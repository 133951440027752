@import '../../../static/css/global/global.scss';

.refre-home-cta-container-pdp,
.refre-home-cta-container {
    position: fixed;
    bottom: 22px;
    width: calc(100% - 32px);
    left: 16px;
    background-color: #262A64;
    border: 1px solid #262A64;
    border-radius: 6px;
    z-index: 9;
    padding: 12px;

    display: flex;
    align-items: center;
    justify-content: space-between;

    .refer-cnct-img {
        margin-right: 7.7px;
    }

    .refer-earn-ttl {
        font-size: 12px;
        font-weight: 600;
        line-height: 16px;
        text-align: left;
        color: #FFF;
        max-width: 157px;
    }

    .refer-link-wrapper {
        .refer-now-btn {
            padding: 5px 10px;
            border-radius: 56px;
            font-size: 12px;
            font-weight: 400;
            line-height: 24px;
            text-align: center;
            color: #262A64;
            background: #FFF;
            cursor: pointer;
        }
    }

    .close-popup-icon {
        cursor: pointer;
    }
}

.refre-home-cta-container-pdp {
    bottom: 95px;
}