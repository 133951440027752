@import 'static/css/global/global.scss';

.shopfor-component {
    padding: 1px 0 0 0;
    .shopfor-inner {
        .sf-heading {
            color: #002140;
            font-family: Barlow;
            font-size: 20px;
            font-style: normal;
            font-weight: 600;
            line-height: normal;
            letter-spacing: .36px;
            text-align: center;
            margin: 40px 0;
        }
    }
}

.sf-filter-1 {
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 20px;

    .f1-inner-tabs {
        display: flex;
        gap: 15px;

        .f1-inner-tab {
            width: 270px;
            height: 68px;
            padding: 20px 26.667px;
            display: flex;
            align-items: center;
            justify-content: space-between;
            background: #ebebeb;
            border-radius: 12px;
            box-shadow: 0 6.666666030883789px 19.999998092651367px #00000052;
            cursor: pointer;

            span {
                font-size: 24px;
            }

            img {
                position: relative;
                bottom: 4px;
            }
        }
    }
}

.sf-filter-2 {
    margin: 40px 0;
    border-bottom: 2px solid #e1e1e1;

    .f2-inner-cont {
        display: flex;

        .f2-inner-tab {
            width: 195px;
            height: 77px;
            display: flex;
            align-items: center;
            padding: 10px 40px;
            justify-content: space-between;
            position: relative;
            cursor: pointer;

            &.active {
                border-bottom: 2px solid;
                span{
                    font-weight: 500;
                }
                // &::before{
                //     position: absolute;
                //     bottom: -2px;
                //     left: 0;
                //     width: 100%;
                //     height: 2px;
                //     background: #2420ed;
                // }
            }

            img {}

            span {
                color: #282960;
                font-family: "Barlow";
                font-size: 20px;
            }
        }
    }
}

