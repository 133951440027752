.f1-inner-tab {
    width          : 60%;
    height         : 68px;
    padding        : 20px 26.667px;
    display        : flex;
    align-items    : center;
    justify-content: space-between;
    background     : #ebebeb;
    border-radius  : 12px;
    box-shadow     : 0px 0px rgba(0, 0, 0, 0.3215686275);
    cursor         : pointer;

    @media screen and (max-width: 1024px) {
        width: 51%;
        height: 42px;
        padding: 8px 16px;
        align-items: center;
        gap: 10px;
        min-width: 170px;
    }


    &.active {
        background: #2420ED;
        box-shadow: 0px 4px 12px 0px rgba(0, 0, 0, 0.32);
        transition: all .25s linear;

        span {
            font-weight: 500;
            color      : #fff;
            transition : all .25s linear;
        }
    }

    @media screen and (min-width: 1024px) {
        width : 270px !important;
        height: 68px;
    }

    span {
        font-size: 24px;
        color    : #575b65;
        @media screen and (max-width: 1023px) {
            font-size: 14px;
        }

    }

    img {
        position: relative;
        bottom  : 4px;
        @media screen and (max-width: 1023px) {
            position: relative;
            width: 50px;
            height: 50px;
            bottom: 4px;
            right: -4px;
        }

    }
}