@import 'static/css/global/global.scss';

.real-moms {
    width: 100%;
    max-width: 1100px;
    float: none;
    margin: 0 auto;
    clear: both;
    padding: 50px 10 70px;
margin-top: 60px;
    .real-moms-title {
        margin: 30px 0px;
        h2 {
            @include fontStyle($font-family-barlow, 20px, 600, 14px, 0px, #002140);
            display        : flex;
            justify-content: center;
        }
        margin-bottom: 30px;

    }
    
    .img_item {
        width: 119px;
        background: transparent;
        padding: 10px 0;
        margin: 5px auto;
        color: #fff;
        -webkit-border-radius: 3px;
        -moz-border-radius: 3px;
        border-radius: 3px;
        text-align: center;
        cursor: pointer;
        .show-brd{
            img{
            border: 2.5px dashed #210b77;
            border-radius: 50% !important;
            }
        }
        .momImgs{
            display: block;

            @media screen and (max-width:1023px) {
                display: flex;
                justify-content: center;
            }

            img{
                display: block;
                width: 100%;
                cursor: pointer;
                @media screen and (max-width:1023px) {
                    max-width: 76px;
                }
            }
        }
    }
}