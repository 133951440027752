.refer-link-wrapper {
    .refer-earn-img-cntnr {
        position: fixed;
        bottom: 20%;
        right: 0px;
        z-index: 9;
        cursor: pointer;
    }
}

.refer-link-wrapper-pdp{
    .refer-earn-img-cntnr {
        position: fixed;
        bottom: 20%;
        right: 0px;
        z-index: 9;
        cursor: pointer;
    }
}