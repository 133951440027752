@import '../../../static/css/global/global.scss';
.loyality-product-price-container{
    background: linear-gradient(90deg, #FFFFFF 0%, #FFECEE 65.62%);
    padding: 9px 7px;
    border-radius: 4px;
    display: flex;
    align-items: flex-end;
    gap: 4px;
    width: max-content;
    margin-bottom: 15px;
    @media screen and (max-width: 1024px) {
        margin-bottom: 0px;
        margin-top: 10px;
        padding: 4px 7px 4px 0px;
        min-width: max-content;
    }

    .loyalty_icon{
        width: 24px;
        height: 28px;
        @media screen and (max-width: 1024px) {
            height: 19px;
            width: 22px;
        }
    }

    .amount-ttl{
        font-size: 18px;
        font-weight: 700;
        line-height: normal;
        color: #E57321;
        @media screen and (max-width: 1024px) {
            font-size: 14px;
        }
        
    }

    .amnt{
        @extend .amount-ttl;
        color: #292D67;
        @media screen and (max-width: 1024px) {
            font-size: 14px;
        }
    }

    .info-icon{
        cursor: pointer;
        margin-bottom: 3px;
       
    }
}

.NoMargin{
    margin-bottom: 0px !important;
    margin-top: 0px !important;
}

.empty-price{
    height: 46px;
    margin-bottom: 15px;
    display: flex;

    @media screen and (max-width: 1024px) {
        margin-bottom: 10px;
        height: 27px;
    }
}