@import '../../../../static/css/global/global.scss';

.onepay {
    @media (max-width: 767px) {
        position: relative;
        left: 7px;
    }

    .offer-item__container {
        width: 150px;
        position: relative;
        right: 18%;

        @media (max-width: 767px) {
            right: 25%;
        }

    }
}

.apply-offer {
    &__container {
        background: #F7F8F9;
        margin-top: 11px;
        margin-bottom: 40px;
        padding: 4px 16px 24px;

        @media (max-width: 1024px) {
            // margin-top: -15px;
            // padding: 15px 10px;
            margin-bottom: 0px;
            margin-top: 0px;
            padding: 4px 0px 24px;
        }

        @media screen and (min-width: 1024px) {
            margin-top: 25px;

        }

        .cart-freebie {
            padding: 10px 20px;
            font-size: 14px;
            line-height: 20px;
            clear: both;
            font-weight: 600;

            span {
                font-weight: 500;
            }
        }
    }

    &__list {
        margin-top: 20px;
        border: 1px dashed;
        border-radius: 10px;
        margin-right: -6px;
        // padding-right: 4px;
        width: 100%;

        @media screen and (max-width: 1024px) {
            border: none !important;

        }

        @media screen and (min-width: 1024px) {
            border: none !important;
        }

        h4 {
            @include fontStyle($font-family-barlow, 16px, 500, 20px, 0px, #20262E);
            padding: 10px;
            padding: 0px 6px;
            margin-bottom: 25px;
            border-left: 4px solid #FFBE00;

            @media (max-width: 1024px) {
                @include fontStyle($font-family-barlow, 16px, 500, 20px, 0px, $header-background);
                padding: 0px 6px;
                border-left: 4px solid #FFBE00;
                margin-bottom: 16px;
            }
        }
    }

    &__new {
        max-width: max-content !important;

        @media screen and (max-width:768px) {
            .one {
                margin-left: 15px;
            }
        }

    }

    &__items {
        margin-top: 0;
        // display: flex;
        flex-direction: row;
        row-gap: 10px;
        justify-content: center;
        align-items: center;

        @media screen and (max-width:768px) {
            display: flex;
        }

        &.one {
            @media screen and (min-width:768px) {
                // position: relative;
                // left: -15%;
            }
        }

        @media screen and (max-width:1024px) {

            .slick-track {
                display: flex !important;
                width: max-content !important;
                padding: 0px 5px;

                .slick-slide {
                    display: flex;
                    height: auto;

                    div {
                        display: flex;
                    }
                }
            }
        }

        @media (min-width: 1024) {
            // margin-top: 12px;
            flex-direction: row;
            padding: 0 10px 5px 10px;

            // &.onepay{
            //     position: relative;
            //     left: -7%;
            // }
            .slick-track {
                display: flex !important;

                .slick-slide {
                    display: flex;
                    height: auto;

                    div {
                        display: flex;
                    }
                }
            }
        }
    }
}

.checkout-process {
    .apply-offer {
        &__container {
            @media (max-width: 767px) {
                // margin-top: -40px;
                margin-top: -15px;
                padding: 15px 10px;
                margin-bottom: -5px;
            }
        }
    }
}

.apply-offer__items {
    .hk-swiper {
        width: 100%;

        &.SpOdesk {
            padding: 0 25px;
            position: relative;

            @media screen and (min-width: 1024px) {
                padding: 0 0px;
            }

            .desk {
                position: unset;
            }
        }

        .SpO {
            width: 100%;
            .swiper-wrapper {
                display: flex !important;
                position: unset;

                .swiper-slide {
                    display: flex;
                    height: auto;

                    div {
                        display: flex;
                    }
                }

                @media screen and (max-width:768px) {
                    width: max-content !important;
                    padding: 0px 5px;
                }
            }

            .swiper-button-prev,
            .swiper-button-next {
                height: 23.5px;
                width: 23.5px;
                top: 54%;
                background-size: contain;

                &::after {
                    display: none;
                }
            }

            .swiper-button-prev {
                background-image: url(https://static1.hkrtcdn.com/gritzo/static/media/newGritzo/grey_arrow_left.svg) !important;
                left: 0 !important;
            }

            .swiper-button-next {
                background-image: url(https://static1.hkrtcdn.com/gritzo/static/media/newGritzo/grey_arrow_right.svg) !important;
                right: 0 !important;
            }

            .swiper-button-disabled {
                opacity: 0.1;
                pointer-events: auto !important;

                &:hover {
                    cursor: not-allowed !important;
                }
            }
        }
    }
}