.tabs-line{
    .tabs-line-inner{
        .sf-filter-2 {
            margin: 40px 0 0 0;
            border-bottom: 2px solid #e1e1e1;
        
            .f2-inner-cont {
                display: flex;
                @media screen and (max-width:1023px) {
                    width: 100%;
                    overflow: scroll;
                    scroll-behavior: smooth;
                }
                &::-webkit-scrollbar {
                            display: none;
                        }
            }
        }
    }
}