@import 'static/css/global/global.scss';

.preview-modal {
    top: 0;
    left: 0;
    right: 0;
    background-color: #2D384499;
    width: 100%;
    height: 100%;
    z-index: 99999;
    position: fixed;

    .preview-container {
        width: 100%;
        height: 100vh;
        background: "#FFF";
        display: flex;
        flex-direction: column;

        .left {
            width: fit-content;
            max-height: 400px;
            height: 47%;

            img {
                width: 100%;
                @media screen and (max-width:1023px) {
                    height: 100%;
                    width: auto;
        max-width: 100%;
                }
            }
        }

        .right {
            height: 53%;
            background: #fff;
            @media screen and (max-width:1023px) {
                height: 100%;
            }
            .header {
                height: 72px;
                border-bottom: 1px solid #2d38445a;
                margin: 0 16px;
                display: flex;
                align-items: center;

                img.insta-logo {
                    border: 0.8px solid #edededd5;
                    border-radius: 49px;
                    height: 45px;
                    width: 45px;
                }

                p {
                    @include fontStyle($font-family-barlow, 17px, 600, 26px, 0, #000000);
                    margin-left: 14px;
                    flex: 1;
                }

                img.close-btn {
                    cursor: pointer;
                }
            }

            .caption {
                position: relative;
                height: calc(100% - 72px);

                p.text {
                    padding: 16px;
                }

                .navigation {
                    display: flex;
                    padding: 16px;
                    justify-content: space-between;

                    img {
                        width: 24px;
                        height: 24px;
                        cursor: pointer;

                        &:first-child {
                            transform: rotate(180deg);
                        }
                    }

                }

                .footer {
                    position: absolute;
                    bottom: 0;
                    left: 0;
                    height: 36px;
                    padding: 0 10px;
                    border-top: 0.8px solid #2d384425;
                    width: 100%;
                    cursor: pointer;

                    span,
                    a {
                        @include fontStyle($font-family-barlow, 11px, 400, 26px, 0, #808080);

                    }
                }
            }
        }

        @media screen and (min-width:1023px) {
            max-height: 80%;
            width: 70%;
            background-color: #fff;
            position: absolute;
            top: 10%;
            left: 15%;
            flex-direction: row;
            .left{
                height: 100%;
                max-height: 100%;
                width: 100%;
                min-width: 650px;
                img{
                    object-fit: fill;
                    height: 100%;
                }
            }
            .right{
                height: 100%;
            }
        }
    }
}