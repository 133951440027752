@import 'static/css/global/global.scss';

.item {
    margin: 10px;
    @media screen and (min-width:1023) {
    margin: 60px;
    }
    .content_item {
        transition: all 1s ease;
        width                : 798px;
        background           : #FFF5F6;
        padding              : 50px 62px;
        margin               : 0 auto;
        color                : #828282;
        -webkit-border-radius: 10px;
        -moz-border-radius   : 10px;
        border-radius        : 10px;
        text-align           : center;
        box-shadow           : inset 0 -1px 4px #0000003d;
        @media screen and (max-width:1023px) {
            padding              : 47px 32px 32px!important;

        }


        .start_comma {
            display        : flex;
            justify-content: flex-start;
        }
        .end_comma{
            display        : flex;
            position: relative;
            right: -100%;
            // float: right;
            @media screen and (min-width:1023px) {
                margin-top: 20px;
                position: relative;
                right: -100%;
            }

        }

        @media screen and (max-width:1023px) {
            width  : 100% !important;
            display: grid;
            margin : auto 0px;
        }

        .testi_content {
            text-align    : justify;
            letter-spacing: .48px;
            margin-bottom : 60px;
            @include fontStyle($font-family-barlow, 14px, 400, 30px, 0px, #828282);

            @media screen and (min-width:1024px) {
                width: 80%;
                margin: auto;
                @include fontStyle($font-family-barlow, 24px !important, 400,30px, 0px, #828282);
            }

        }

        .testi_name {
            letter-spacing: .48px;
            @include fontStyle($font-family-barlow, 24px, 600, 30px, 0px, #1c2717);
            margin: auto
        }

        .testi_place {
            @include fontStyle($font-family-barlow, 20px, 500, 30px, .4px, #696969);
            display: flex;
            justify-content: center;
            align-items: center;
            text-align: center;
        }
    }
}