@import 'static/css/global/global.scss';

.gritzo-india {
    // width: 100%;
    // max-width: 1100px;
    // float: none;
    margin: 0 auto;
    // clear: both;
    // padding: 50px 10px 70px;

    .gritzo-india-title {
        margin: 30px 0px;

        h2 {
            @include fontStyle($font-family-barlow, 20px, 600, 14px, 0px, #002140);
            display: flex;
            justify-content: center;
        }

        margin-bottom: 30px;

    }

    .main-card-container {
        width: 80%;
        margin: 0 auto;

        @media screen and (min-width:1023px) {
            width: 100%;
            // max-width: 964px;
        }

        .img-card-conta {
            display: inline-block;
            position: relative;
            vertical-align: top;
            padding: 0;
            color: #fff;
            min-width: 100px;
            height: 256px;
            margin: 10px;
            cursor: pointer;
            @media screen and (max-width:767px) {
                width: 66px;
                height: 66px;
                min-width: unset;

                img {
                    width: 100% !important;
                    height: auto;
                }
            }

            img {
                display: block;
                width: 100%;
                position: relative;
                box-shadow: 0px 0px 36.67668151855469px 0px #0000003D;
                border-radius: 16px;
            }

            .instafeed-overlay {
                opacity: 0;
                position: absolute;
                display: block;
                background-color: transparent;
                top: 0;
                left: 0;
                bottom: 0;
                right: 0;
                width: 100%;
                height: 100%;
                margin: 0 auto;
                transition: .2s linear;
            }

            .instafeed-overlay::before {
                content: ' ';
                opacity: 1;
                background: url(https://instafeed.nfcube.com/assets/img/instagram-icon-v2.svg) center center/20px 20px no-repeat;
                position: absolute;
                z-index: 100;
                top: 0;
                left: 0;
                bottom: 0;
                right: 0;
            }

            .instafeed-overlay::after {
                content: ' ';
                position: absolute;
                top: 0;
                left: 0;
                width: 100%;
                height: 100%;
                background-color: #000;
                opacity: .5;
            }

            .instafeed-overlay:hover {
                content: ' ';
                opacity: 1;
                background: url(https://instafeed.nfcube.com/assets/img/instagram-icon-v2.svg) center center/20px 20px no-repeat;
                position: absolute;
                z-index: 100;
                top: 0;
                left: 0;
                bottom: 0;
                right: 0;
                background-color: transparent;
            }
        }
    }
}