.star-calc {
    width: 10px;
    height: 10px;
    position: relative;

    img {
        width: 10px;
        height: 10px;
        display: block;
    }

    .filled {
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        min-height: 10px;
        background-size: cover;
        background-image: url(https://static1.hkrtcdn.com/gritzo/static/media/review/filled-star-new.svg);
        &.yellow{
            background-image: url(https://static1.hkrtcdn.com/gritzo/static/media/review/filled-star-new-yellow.svg);
        }
    }
}
