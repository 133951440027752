// @import "~slick-carousel/slick/slick.css";
// @import "~slick-carousel/slick/slick-theme.css";
@import "../../../../static/css/global/global.scss";

.mweb-home-slider {
    margin-bottom: -11px;
    min-height: 250px;
    .slider-container{
        height: 100%;
        min-height: inherit;
        .slick-slider{
            height: 100%;
            min-height: inherit;
            .slick-list{
                height: 100%;
                min-height: inherit;
                .slick-track{
                    height: 100%;
                    min-height: inherit;
                    .slick-slide{
                        height: 100%;
                        min-height: inherit;
                        :first-child {
                            height: 100%;
                            min-height: inherit;
                        }
                    }
                }
            }
        }
    }
    .banner-item {
        display: block;
        position: relative;
        border-bottom: none;
        height: 100%;
        min-height: inherit;
        @media screen and (max-width: 1024px) {
            min-height: 245px;
        }

        img {
            width: 100%;
        }
    }

    .banner-txt {
        position: absolute;
        top: 20%;
        left: 30px;
        padding-left: 12px;

        li {
            @include fontStyle($fontFamily-oswald, 16px, 500, 21px, 0.64px, #ffffff);
        }
        li:nth-last-child(2) {
            @include fontStyle($fontFamily-oswald, 54px, 500, 54px, 0.54px, $yellow8);
        }
        li:last-child {
            @include fontStyle($fontFamily-oswald, 31px, 500, 31px, 0.31px, #ffffff);
        }

        &:before {
            content: "";
            width: 3px;
            height: 100%;
            background-color: #ffffff;
            position: absolute;
            left: 0;
        }
    }

    .slick-dots {
        bottom: 26px;

        li {
            margin: 0 3px;
            width: 6px;
            height: 6px;
            button {
                width: 6px;
                height: 6px;
                &:before {
                    color: #ffffff;
                    opacity: 0.2;
                    width: 6px;
                    height: 6px;
                }
            }

            &.slick-active {
                width: 7px;
                height: 7px;

                button {
                    width: 7px;
                    height: 7px;
                    &::before {
                        opacity: 1;
                        font-size: 7px;
                        width: 7px;
                        height: 7px;
                    }
                }
            }
        }
    }

    .slick-next, .slick-prev{
        background-color: white;
        color:white;
        z-index: 5;
    }

    .slick-next{
        right: 30px;
    }

    .slick-prev{
        left: 30px;
    }
}
