.f2-inner-tab {
    width: 195px;
    height: 75px;
    display: flex;
    justify-content: center;
    align-items: center;
    align-items: center;
    position: relative;
    cursor: pointer;

    &.active {
        span{
            font-weight: 500;
            transition: all .25s linear;
            @media screen and (max-width:1024px) {
             font-size: 16px;
             max-height: 49px;
             overflow-y: scroll;   
            }
        }
        &::after{
            content: "";
            position: absolute;
            bottom: -2px;
            left: 0;
            width: 100%;
            height: 2px;
            // background: #2420ed;
            background: #000000;
            transition: all .25s linear;
        }
    }

    img {
        @media screen and (max-width:1024px) {
            width: 40px; 
            height: 40px;
        }
    }

    span {
        margin: 0 0 0 10px;
        color: #282960;
        font-family: "Barlow";
        font-size: 20px;
        @media screen and (max-width:1024px){
            font-size:16px;
        }
    }
}
