@import '../../../static/css/global/global.scss';

.nps-cta-parent-container {
    width: 100%;
    background-image: url(https://static1.hkrtcdn.com/gritzo/static/media/Homepage/outline.svg);
    background-image: 100%;
    background-repeat: no-repeat;
    position: fixed;
    bottom: 0px;
    z-index: 10;
    height: 65px;

    .body-cntnr {
        position: relative;
        height: 100%;
        width: 100%;
        display: flex;
        align-items: center;
        justify-content: center;
        padding-top: 10px;
        padding-left: 60px;
        max-width: 380px;

        .red-circle {
            width: 37px;
            height: 37px;
            flex-shrink: 0;
            border-radius: 100%;
            background-color: #c23f1e;
            left: 18px;
            top: 5px;
            position: absolute;

            .star-icon {
                width: 100%;
            }
        }

        .close-icon{
            margin-left: 10px;
        }

        .ttl-details-cntnr {
            padding-right: 31px;
            .ttl {
                color: #C12C1F;
                font-size: 16px;
                font-style: normal;
                font-weight: 700;
                line-height: normal;
            }

            .dtls {
                color: #000;
                font-size: 13px;
                font-style: normal;
                font-weight: 400;
                line-height: normal;
            }
        }

        .rate-now-btn {
            padding: 5px 10px;
            border-radius: 7px;
            background: #C12C1F;
            border: none;

            color: #FFF;
            text-align: center;
            font-size: 12px;
            font-style: normal;
            font-weight: 700;
            line-height: normal;
            letter-spacing: 0.6px;
        }
    }
}