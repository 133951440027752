.tabs-box {
    .tabs-box-inner {
        .sf-filter-1 {
            display: flex;
            align-items: center;
            justify-content: center;
            gap: 20px;

            .f1-inner-tabs {
                display: flex;
                gap: 15px;
                justify-content: center;
            }
        }
    }
}