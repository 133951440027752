.video-card {
    .videoPoster {
        // background-image: url(//i.ytimg.com/vi/XzduB8tTMvE/hqdefault.jpg);
        position: relative;
        // position: absolute;
        // top: 0;
        // right: 0;
        // left: 0;
        width: 100%; //532px;
        height: 298px;
        margin: 0;
        padding: 0;
        cursor: pointer;
        border: 0;
        outline: none;
        background-position: 50% 50%;
        background-size: 100% 100%;
        background-size: cover;
        text-indent: -999em;
        overflow: hidden;
        opacity: 1;
        -webkit-transition: opacity 800ms, height 0s;
        -moz-transition: opacity 800ms, height 0s;
        transition: opacity 800ms, height 0s;
        -webkit-transition-delay: 0s, 0s;
        -moz-transition-delay: 0s, 0s;
        transition-delay: 0s, 0s;
        border-radius: 24px;
        box-shadow: 0px 0px 30.515033721923828px 0px #00000066;
        @media screen and (max-width:767px) {
            height: auto;
        }

        svg {
            overflow: hidden;
            vertical-align: middle;
        }

        .play-vid {
            cursor: pointer;
            margin: auto;
            position: absolute;
            top: 0;
            left: 0;
            bottom: 0;
            right: 0;
            opacity: 0.85;
            width: 110px;
            height: 110px;
            .stroke-solid {
                stroke-dashoffset: 0;
                stroke-dasharray: 300;
                stroke-width: 4px;
                -webkit-transition: stroke-dashoffset 1s ease, opacity 1s ease;
                transition: stroke-dashoffset 1s ease, opacity 1s ease;
                opacity: 0;
            }
            .stroke-dotted {
                stroke-dasharray: 4, 5;
                stroke-width: 1px;
                -webkit-transform-origin: 50% 50%;
                transform-origin: 50% 50%;
                -webkit-animation: spin 4s infinite linear;
                animation: spin 4s infinite linear;
                -webkit-transition: opacity 1s ease, stroke-width 1s ease;
                transition: opacity 1s ease, stroke-width 1s ease;
                animation-name: rotate;
                animation-duration: 5s;
                animation-iteration-count: infinite;
                animation-timing-function: linear;
                border-radius: 50%;
            }
            .vid-icon {
                -webkit-transition: -webkit-transform 200ms ease-out;
                transition: -webkit-transform 200ms ease-out;
                transition: transform 200ms ease-out;
                transition: transform 200ms ease-out, -webkit-transform 200ms ease-out;
            }
        }
        .video-ele{
            position: absolute;
            top: 0;
            left: 0;
            width: 100%;
        }
    }
}

.video-cont{
    position: relative;
    svg{
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translate(-50%,-50%);
      width: 30%;
    }
    .video-ele{
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translate(-50%,-50%);
      width: 100%;
    }
  }