// @import "~slick-carousel/slick/slick.css"; 
// @import "~slick-carousel/slick/slick-theme.css";
@import '../../../../static/css/global/global.scss';

.desktop-home-slider {
    // min-height: 512px;
    min-height: 300px;

    .slider-container {
        position: relative;
        min-height: inherit;

        .refer-link-wrapper {
            .refer-earn-img-cntnr {
                position: absolute;
                bottom: 20%;
                right: 0px;
                z-index: 9;
                cursor: pointer;
            }
        }

        .banner-item {
            position: relative;
            display: block;

            .banner-img {
                // min-height: 631px;
                min-height: 300px;
            }

            .banner-txt {
                position: absolute;
                top: 50%;
                left: 10%;
                transform: translateY(-50%);
                padding-left: 28px;

                li {
                    @include fontStyle($fontFamily-oswald, 32px, 500, 42px, 4.16px, #FFFFFF);
                }

                li:nth-last-child(2) {
                    @include fontStyle($fontFamily-oswald, 108px, 500, 108px, 1.08px, $yellow8);
                }

                li:last-child {
                    @include fontStyle($fontFamily-oswald, 62px, 500, 62px, 0.62px, #FFFFFF);
                }

                &:before {
                    content: '';
                    width: 6px;
                    height: 100%;
                    background-color: #FFFFFF;
                    position: absolute;
                    left: 0;
                }
            }
        }
    }

    .slider-1 {
        min-height: inherit;

        &::after {
            content: '';
            background-color: $yellow_color;
            width: 100%;
            height: 10px;
            position: absolute;
            bottom: 0;
            left: 0;
        }
    }

    .slider-2 {
        position: absolute;
        left: 0;
        bottom: 0;
        width: 55%;
        margin-bottom: -3%;
        border-bottom: 4px solid $yellow_color;

        .slick-slide img {
            width: 100%;
        }

        .nav-item {
            display: block !important;
            cursor: pointer;
        }
    }

    .slick-next,
    .slick-prev {
        // background-color: white;
        //color:white;
        z-index: 5;
        border-radius: 30px;
        height: 30px;
        width: 30px;
    }

    .slick-next {
        right: 30px;
    }

    .slick-prev {
        left: 30px;
    }
}